// Chatwoot.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "./selectors/usersSelectors";

const ChatwootWidget = () => {
   const user = useSelector((state) => getCurrentUser(state));

   useEffect(() => {
      const BASE_URL = "https://uboschat-6825-674858decc7268110000013e.chatwoot-prod.ubos.tech";

      window.chatwootSettings = {
         hideMessageBubble: false,
         position: "left",
         locale: "en",
         type: "standart",
      };

      (function (d, t) {
         const g = d.createElement(t), s = d.getElementsByTagName(t)[0];
         g.src = BASE_URL + "/packs/js/sdk.js";
         g.defer = true;
         g.async = true;
         s.parentNode.insertBefore(g, s);
         g.onload = function () {
            window.chatwootSDK.run({
               websiteToken: "FHKsyR2mCEJF4V4Vzp5Zs6YM",
               baseUrl: BASE_URL,

            });
         };
      })(document, "script");
   }, []);

   useEffect(() => {
      if (user && window.$chatwoot) {
         console.log(user.id, "useruseruser")
         window.$chatwoot.setUser(user.id, {
            email: user.email,
            name: user.name,
            avatar_url: "",
            phone_number: "",
         });
      }
   }, [user]);

   return null;
};

export default ChatwootWidget;
